<template>
	<div
		id="context-menu"
		ref="contextMenu"
		class="absolute shadow-xl bg-color-white rounded-xl p-4 z-[100]"
		:class="$isMobile.any ? `${width || 'w-[300px]'}` : `${width || 'w-[350px]'}`"
	>
		<div id="arrow" class="absolute" data-popper-arrow />
		<slot />
	</div>
</template>

<script lang="ts">
import { Placement, computePosition, autoUpdate, offset, arrow } from '@floating-ui/dom';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'ContextMenu',
	emits: ['close'],
	data() {
		const cleanup = undefined as undefined | Function;
		return { cleanup };
	},
	async mounted() {
		const item = document.querySelector(this.selector) as HTMLElement;
		this.cleanup = autoUpdate(item, this.contextMenu, () => {
			this.handleMenuPosition();
		});
		setTimeout(() => {
			window.addEventListener('click', this.closeOnOutsideClick, { passive: true });
		}, 100);
	},
	async beforeUnmount() {
		// Make sure to create a new floatingui instance in case you're navigating to another page with an active callOut on it
		// to reset the placement.
		if (this.cleanup) {
			this.cleanup();
			this.cleanup = undefined;
		}
	},
	methods: {
		async handleMenuPosition() {
			const item = document.querySelector(this.selector) as HTMLElement;
			const arrowElement = document.querySelector('#arrow') as HTMLElement;
			const { x, y, placement, middlewareData } = await computePosition(item, this.contextMenu, {
				placement: this.placement ? (this.placement as Placement) : ('right-start' as Placement),
				middleware: [
					offset(() => ({ mainAxis: 10, crossAxis: 0 })),
					arrow({ element: arrowElement })
				]
			});
			Object.assign(this.contextMenu.style, {
				left: `${x}px`,
				top: `${y}px`
			});
			const arrowX = middlewareData?.arrow?.x;

			const arrowY = middlewareData?.arrow?.y;

			const staticSide: any = {
				top: 'bottom',
				right: 'left',
				bottom: 'top',
				left: 'right'
			}[placement.split('-')[0]];
			if (arrowElement) {
				Object.assign(arrowElement.style, {
					left: arrowX != null ? `${arrowX}px` : '',
					top: arrowY != null ? `${arrowY}px` : '',
					right: '',
					bottom: '',
					[staticSide]: '-8px'
				});
			}
		},
		closeOnOutsideClick(event) {
			const isClickInsideElement = (this.$refs?.contextMenu as Node)?.contains(event.target);
			if (!isClickInsideElement) {
				if (this.cleanup) {
					this.cleanup();
					this.cleanup = undefined;
				}
				this.$emit('close');
			}
		}
	},
	props: {
		selector: { type: String, required: true },
		placement: { type: String, required: true },
		width: { type: String, required: false }
	},
	computed: {
		contextMenu() {
			return this.$refs.contextMenu as HTMLElement;
		}
	}
});
</script>

<style lang="scss" scoped>
#arrow::before {
	position: absolute;
}
</style>
